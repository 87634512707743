.my-project-container{
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 50px;
    padding-bottom: 50px;
    background-color: #E8F6FB;
}
.all-title-name{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Bold;
    font-size: 2.0em;
}
.my-project-items{
    margin-top: 50px;
    margin-right: 20px;
    width: 350px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 16px;
    background-color: #FFF;
    border-radius: 10px;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 30px -15px;
    border: 1px solid rgba(211, 211, 211, 0.397);
    cursor: pointer;
}
.my-project-icon-container{
    width: 80px;
    height: 80px;
    border-radius: 10px;
    background-color: #286F6C;
}
.my-project-name{
    font-family: Bold;
    font-size: 1.4em;
}
.my-project-company{
    font-family: medium;
    font-size: 1.0em;
    color: #aaa;
}
.my-project-position{
    margin-top: 16px;
    font-family: bold;
    font-size: 1.1em;
    color: #000;
    padding-right: 10px;
}
.my-project-body{
    margin-top: 16px;
    font-family: medium;
    font-size: 1.0em;
    color: #000;
    padding-right: 10px;
}
.myskill-tag{
    font-family: medium;
    padding: 6px;
    font-size: 0.9em;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 6px;
}
.my-project-button{
    margin-top:16px;
    margin-bottom:10px;
    align-content: center;
    font-family: medium;
    font-size: 1.0em;
}
.my-project-visit-button{
    width: 100%;
    margin-top:2px;
    margin-bottom:2px;
    align-content: center;
    font-family: medium;
    font-size: 1.0em;
}