.m-ido-container{
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 40px;
    padding-bottom: 40px;
}
.m-left-ido{
}
.m-ido-titleText{
    font-family: bold;
    font-size: 7.0vmin;
}
.m-right-ido{
    margin-top: 40px;
}
.m-li{
    font-family: medium;
    font-size: 3.5vmin;
}
.m-bottom-i-do{
    margin-top: 30px;
    display: flex;
    justify-content: center;
}
.m-bottom-i-do-number{
    font-family: Bold;
    font-size: 3.0vmax;
    color: #286F6C;
    display: flex;
    justify-content: center;
}
.m-bottom-i-do-txt{
    display: flex;
    justify-content: center;
    font-family: medium;
    font-size: 2.0vmax;
    color: #286F6C;
}
.m-right-ido-items{
    margin-top: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* width: 100%; */
    height: 100px;
    border-radius: 20px;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 30px -15px;
    border: 1px solid rgba(211, 211, 211, 0.397);
}
.m-icon-container{
    width: 45px;
    height: 45px;
    border-radius: 30px;
    background-color: #286F6C;
    display: flex;
    justify-content: center;
    align-items: center;
}
.m-icon-container i {
    color: white;
    border-radius: 2.6rem;
    cursor: pointer;
    display: inline-block;
    font-size: 1.2rem;
    height: 2.0rem;
    line-height: 2.0rem;
    margin: 0 5px;
    position: relative;
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 2.0rem;
    /* margin-top: 5px; */
    transition: 0.2s ease-in;
  }
.m-right-txt-cols{
    margin-left: 15px;
}
.m-right-ido-items-name{
    font-family: bold;
    font-size: 4.5vmin;
}
.m-right-ido-items-project{
    font-family: medium;
    font-size: 3.5vmin;
    color: #aaa;
}