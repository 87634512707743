.m-intro-container{
    padding: '0 50px';
    background-color: #E8F6FB;
    min-height: 100px;
}
.m-titleContainer{
    padding-top: 40px;
    padding-left: 10%;
    padding-right: 10%;
    height: 200px;
}
.m-titleText{
    font-family: bold;
    font-size: 7.1vmin;
}
.m-image-container{
    display: flex;
    flex-direction: row;
    padding-left: 0%;
    margin-top: 10px;
    padding-right: 0%;
}
.m-leftIntro{
    padding-left: 10%;
    padding-right: 10%;
    margin-top: 46px;
    flex:3;
    display: flex;
    align-items: flex-end;
    padding-bottom: 24px;
    flex-direction: row;
}
.m-noOfExp{
    font-family: bold;
    font-size: 5.0vmax;
    color: #286F6C;
}
.m-rightExpContainer{
    flex: 1;
    padding-bottom: 8px;
    margin-left: 10px;
}
.m-yearsTitle{
    font-family: medium;
    font-size: 1.6vmax;
    color: #286F6C;
}
.m-rightIntro{
    /* background-color: red; */
}
.m-personal-image_bg{
    /* width: 100%; */
    height: 200px;
    background-image: url("../../assets/image/background_image.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size:contain;
    display: flex;
    justify-content: center;
    align-items: center;
}
.m-profile-image{
    width: 60%; 
    object-fit: contain;
    box-shadow: 0px 2px 98px -12px rgba(0,0,0,0.5);
    border-radius: 150px;
}