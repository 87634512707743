.m-my-footer-container{
    padding: 20px;
    background-color: #E8F6FB;
}
.m-footer-menu-message{
    font-family: medium;
    font-size: 2.0em;
    color: #000;
}
.m-footer-menu-text{
    text-align: center;
    font-family: medium;
    font-size: 0.8em;
    color: #000;
}
.m-footer-menu-social-container{
    margin-top: 10px;
    margin-bottom: 20px;
    padding: 8px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 12px;
    background: #FFF;
    border-radius: 8px;
    /* width: 300px; */
}