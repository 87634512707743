.m-my-project-container{
    padding: 16px;
    background-color: #E8F6FB;
    overflow-y:auto
}
.m-my-project-items{
    margin-top: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 16px;
    background-color: #FFF;
    border-radius: 10px;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 30px -15px;
    border: 1px solid rgba(211, 211, 211, 0.397);
}
.m-my-project-icon-container{
    width: 80px;
    height: 80px;
    border-radius: 10px;
    background-color: #286F6C;
}
.m-my-project-name{
    font-family: Bold;
    font-size: 1.4em;
}
.m-my-project-company{
    font-family: medium;
    font-size: 1.0em;
    color: #aaa;
}
.m-my-project-position{
    margin-top: 16px;
    font-family: bold;
    font-size: 1.1em;
    color: #000;
    padding-right: 10px;
}
.m-my-project-body{
    margin-top: 16px;
    font-family: medium;
    font-size: 1.0em;
    color: #000;
    padding-right: 10px;
}
.m-my-project-button{
    margin-top:16px;
    margin-bottom:10px;
    align-content: center;
    font-family: medium;
    font-size: 1.0em;
}
.m-my-project-visit-button{
    width: 100%;
    margin-top:2px;
    margin-bottom:2px;
    align-content: center;
    font-family: medium;
    font-size: 1.0em;
}