@font-face {
  font-family: "black";   /*Can be any text*/
  src: local("Jost Black"),
    url("./app/assets/fonts/Jost-Black.ttf") format("truetype");
}

@font-face {
  font-family: "bold";   /*Can be any text*/
  src: local("Jost Bold"),
    url("./app/assets/fonts/Jost-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "extra_bold";   /*Can be any text*/
  src: local("Jost ExtraBold"),
    url("./app/assets/fonts/Jost-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: "medium";   /*Can be any text*/
  src: local("Jost Medium"),
    url("./app/assets/fonts/Jost-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "light";   /*Can be any text*/
  src: local("Jost Light"),
    url("./app/assets/fonts/Jost-Light.ttf") format("truetype");
}


html{
  scroll-behavior: smooth;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
