.toolbar{
    padding-top: 20px;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
}
.menu{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.menuItem-a{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
}
.menuItem{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 40px;
    width: 80px;
    color: #286F6C;
    border-radius: 20px;
    margin-left: 10px;
    /* background-color: pink; */
}

.menuItemResume{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 40px;
    width: 140px;
    color: #286F6C;
    margin-left: 10px;
    /* background-color: pink; */
}
.menuItemResumeBtn{
    background:#FFF;
    padding-left:16px;
    padding-right:16px;
    height:40px;
    display:flex;
    justify-content:center;
    align-items:center;
    border-radius:6px;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 30px -15px;
    border: 1px solid rgba(211, 211, 211, 0.397);
}

a{
    color: #286F6C;
}

.menuItemTxt{
    font-family: medium;
}

.menuItemCallIcon{
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    height: 30px;
    width: 30px;
    border-radius: 20px;
    background-color: #FFF;
}