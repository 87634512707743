.experience-container{
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 50px;
    padding-bottom: 50px;
    justify-content: center;
    background-color: #E8F6FB;
}
.exp-cont-item{
    /* width: 550px; */
    /* margin-left: 50px; */
    position: relative;
    right: 40px;
    background-color: #FFF;
    padding: 20px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 20px;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 30px -15px;
    border: 1px solid rgba(211, 211, 211, 0.397);
    text-align: left;
    cursor: pointer;
}
.experience-desc-container{
    font-family: medium;
    font-size: 1.1em;
    padding-left: 40px;
}
.experience-timeline-dot{
    background-color: red;
    width: 20px;
    height: 20px;
    /* border-radius: 20px; */
}
.exp-cont-item-company{
    font-family: bold;
    font-size: 1.1em;
}
.exp-cont-item-date{
    font-family: medium;
    font-size: 0.9em;
}
.exp-cont-item-location{
    font-family: medium;
    font-size: 1.0em;
}
.container-title{
    font-family: bold;
    font-size: 2.0em;
}