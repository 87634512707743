.ido-container{
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 50px;
    padding-bottom: 50px;
    display: flex;
    flex-direction: row;
    /* background-color: #FFF; */
}
.left-ido{
    flex: 1;
}
.right-ido{
    flex: 1;
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    justify-content: center;
}
li{
    font-family: medium;
    font-size: 16px;
}
.m-li-txt{
    font-family: medium;
    font-size: 16px;
}
.sub-text{
    font-family: medium;
    font-size: 14px;
}
.bottom-i-do{
    margin-top: 30px;
}
.bottom-i-do-number{
    font-family: Bold;
    font-size: 30px;
    color: #286F6C;
}
.bottom-i-do-txt{
    font-family: medium;
    font-size: 16px;
    color: #286F6C;
}
ol{
    margin-top: 30px;
}
.right-ido-items{
    /* position: relative;
    right: -200px; */
    margin-top: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 400px;
    height: 100px;
    background-color: #FFF;
    border-radius: 20px;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 30px -15px;
    border: 1px solid rgba(211, 211, 211, 0.397);
    cursor: pointer;
}
.icon-container{
    width: 65px;
    height: 66px;
    border-radius: 50px;
    background-color: #286F6C;
    display: flex;
    justify-content: center;
    align-items: center;
}
.icon-container i {
    color: white;
    border-radius: 2.6rem;
    cursor: pointer;
    display: inline-block;
    font-size: 1.7rem;
    height: 2.0rem;
    line-height: 2.0rem;
    margin: 0 5px;
    position: relative;
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 2.0rem;
    /* margin-top: 5px; */
    transition: 0.2s ease-in;
  }
.right-txt-cols{
    margin-left: 20px;
}
