.m-menu {
  display: flex;
  flex-direction: row;
}

.m-logo-container {
  display: flex;
  flex-direction: row;
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.m-menu{
  display: flex;
  flex-direction: row;
}

.m-logo {
    display: block;
    flex: none;
    float: left;
    font-size: 1.5em;
    text-decoration: none;
    margin-top: 10px;
    line-height: normal;
  }
.m-logo-name {
    font-family: "Agustina Regular";
    font-weight: bold;
    font-variant-ligatures: no-common-ligatures;
    -webkit-font-variant-ligatures: no-common-ligatures;
    padding: 0 10px;
    color: #286F6C;
  }

  .m-menuItem{
      display: flex;
      justify-content: flex-end;
      height: 40px;
      color: #286F6C;
      /* background-color: red; */
  }
  
  .m-menuItemResumeBtn{
    background:#FFF;
    padding-left:16px;
    padding-right:16px;
    height:40px;
    display:flex;
    justify-content:center;
    align-items:center;
    border-radius:6px;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 30px -15px;
    border: 1px solid rgba(211, 211, 211, 0.397);
}
  .m-menuItemTxt{
      font-family: bold;
      font-size: 3.0vw;
  }
  
  .m-menuItemCallIcon{
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      height: 30px;
      width: 30px;
      border-radius: 20px;
      background-color: #FFF;
      margin-left: 10px;
      box-shadow: -2px 0px 24px -7px rgba(0,0,0,0.61);;
  }
  .m-phone-div{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
  }

  .m-menuItem-phone{
    margin-left: 60px;
    width: 150;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .m-menuItemCallIcon i {
    color: #286F6C;
    border-radius: 2.6rem;
    cursor: pointer;
    display: inline-block;
    font-size: 0.8rem;
    height: 2.0rem;
    line-height: 2.0rem;
    margin: 0 5px;
    position: relative;
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 2.0rem;
    /* margin-top: 5px; */
    transition: 0.2s ease-in;
  }