.my-footer-container{
    padding: 20px;
    padding-left: 100px;
    padding-right: 100px;
    background-color: #E8F6FB;
}
.footer-menu-message{
    font-family: medium;
    font-size: 3.0em;
    color: #000;
}
.footer-menu-text{
    font-family: medium;
    font-size: 1.0em;
    color: #000;
}
.footer-menu-social-container{
    padding: 8px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 12px;
    background: #FFF;
    border-radius: 8px;
    /* width: 300px; */
}