.m-my-skills-container{
    /* background-color: #E8F6FB; */
    padding: 16px;
}
.m-myskill-items{
    margin-top: 16px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 16px;
    display: flex;
    /* width: 400px; */
    /* height: 100px; */
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 30px -15px;
    border: 1px solid rgba(211, 211, 211, 0.397);
    border-radius: 10px;
}
.m-myskill-icon-container{
    width: 45px;
    height: 46px;
    border-radius: 50px;
    background-color: #286F6C;
    display: flex;
    justify-content: center;
    align-items: center;
}
.m-myskill-icon-container i {
    color: white;
    border-radius: 2.6rem;
    cursor: pointer;
    display: inline-block;
    font-size: 1.2rem;
    height: 2.0rem;
    line-height: 2.0rem;
    margin: 0 5px;
    position: relative;
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 2.0rem;
    /* margin-top: 5px; */
    transition: 0.2s ease-in;
  }
.m-myskill-i-do-number{
    font-family: Bold;
    font-size: 1.2em;
}
.m-myskill-i-do-txt{
    font-family: medium;
    font-size: 1.0em;
    color: #aaa;
}
.m-myskill-tag{
    font-family: medium;
    padding: 4px;
    font-size: 0.9em;
    padding-left: 6px;
    padding-right: 6px;
    margin-top: 6px;
}