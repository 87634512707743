.m-experience-container{
    background-color: #E8F6FB;
    padding-top: 20px;
}
.m-container-title{
    text-align: center;
    /* padding: 20px; */
    font-size: 1.5em;
    font-family: bold;
}
.m-experience-container-inside{
    padding-top: 40px;
    padding-bottom: 20px;
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    
}
.m-exp-cont-item{
    width: 100%;
    margin-left: 2%;
    background-color: #FFF;
    padding: 5%;
    padding-left: 5%;
    border-radius:10px;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 30px -15px;
    border: 1px solid rgba(211, 211, 211, 0.397);
}
.m-exp-cont-item-company{
    font-family: bold;
    font-size:  1.1em;
}
.m-exp-cont-item-date{
    font-family: medium;
    font-size:  0.9em;
}
.m-exp-cont-item-location{
    font-family: medium;
    font-size:  1.0em;
}
