.intro-container{
    padding: '0 50px';
    background-color: #E8F6FB;
    /* height: 750px; */
}
.titleContainer{
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 50px;
}
.titleText{
    font-family: bold;
    font-size: 50px;
}
.image-container{
    display: flex;
    flex-direction: row;
    padding-left: 100px;
    padding-right: 100px;
}
.leftIntro{
    flex:3;
    display: flex;
    align-items: flex-end;
    padding-bottom: 50px;
    flex-direction: row;
}
.noOfExp{
    font-family: bold;
    font-size: 50px;
    color: #286F6C;
}
.rightExpContainer{
    flex: 1;
    padding-bottom: 16px;
    margin-left: 10px;
}
.yearsTitle{
    font-family: medium;
    font-size: 14px;
    color: #286F6C;
}
.rightIntro{
    /* background-color: red; */
    justify-content: center;
    align-items: center;
    flex:6;
    /* height: 400px; */
}
.personal-image_bg{
    /* height: 600px; */
    background-image: url("./../assets/image/background_image.png");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
.profile-image{
    /* height: 250px; 
    width: 250px;  */
    width: 25%; 
    object-fit: contain;
    box-shadow: 0px 2px 98px -12px rgba(0,0,0,0.5);
    border-radius: 150px;
}