.my-reviews-container{
    padding: 20px;
    padding-left: 100px;
    padding-right: 100px;
    padding-top: 50px;
    padding-bottom: 50px;
    /* background-color: #FFF; */
    /* display: flex; */
    /* justify-content: center;
    align-items: center; */
    /* background-color: #E8F6FB; */
}
.my-reviews-items{
    margin-top: 70px;
    padding-top: 10px;
    padding-bottom: 20px;
    padding-left: 16px;
    padding-right: 16px;
    width: 350px;
    text-align: center;
    /* height: 100px; */
    border-radius: 10px;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.2) 0px 10px 30px -15px;
    border: 1px solid rgba(211, 211, 211, 0.397);
    cursor: pointer;
}
.my-reviews-picture{
    position: relative;
    bottom: 50px;
    width: 100px;
    height: 100px;
    border-radius: 50px;
    background-color: #286F6C;
    display: flex;
    justify-content: center;
    align-items: center;
}
.my-reviews-name{
    font-family: Bold;
    font-size: 1.2em;
}
.my-reviews-at{
    font-family: medium;
    font-size: 1.0em;
    color: #aaa;
}
.my-reviews-text{
    font-family: medium;
    font-size: 1.0em;
    color: #0D2F3F;
    text-align: center;
}
.my-reviews-company, .my-reviews-position{
    font-family: medium;
    font-size: 1.1em;
    color: #0D2F3F;
    text-align: center;
}

.my-reviews-company{
    font-size: 1.0em;
}
.my-reviews-tag{
    font-family: medium;
    padding: 6px;
    font-size: 0.9em;
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 6px;
}